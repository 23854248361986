<template>
    <div>
      <v-dialog
        :fullscreen="$vuetify.breakpoint.xsOnly"
        v-model="show"
        max-width="1000px"
        max-height="1000px"
        scrollable
        persistent
      >
        <div class="text-center" v-if="loading" style="min-height: 500px">
          <v-progress-circular
            :size="100"
            :width="10"
            style="
              text-align: center;
              padding-top: 20px;
              padding-bottom: 20px;
              margin-top: 200px;
            "
            color="#7253CF"
            indeterminate
          ></v-progress-circular>
        </div>
  
        <v-card background-color="#f2f2f2" v-else>
          <v-card-title
            class="py-0 pt-7"
            style="background-color: #7253cf; color: white"
          >
            <div class="d-flex px-1">
              <img src="@/assets/teamModalLogo.svg" height="85" width="100" />
            </div>
            <div class="ml-1 dialogTitle-text">
              Table
            </div>
            <v-spacer></v-spacer>
            <v-icon
              text
              large
              color="white"
              style="
                cursor: pointer;
                position: relative;
                right: -15px;
                bottom: 40px;
              "
              @click="toggleGameTableModal({ show: false });"
              >mdi-close
            </v-icon>
          </v-card-title>
          <v-card-text class="pt-5">
            <div>
                <v-simple-table class="elevation-8 custom-table" style="margin-top: 2%">
                    <template v-slot:default>
                    <thead>
                        <tr class="table-header">
                        <th style="width: 200px">Details</th>
                        <th
                            class="textHeader text-center"
                            v-for="(item, i) in labels"
                            :key="i"
                        >
                            {{ item }}
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, i) in tableresponseData.rows" :key="i">
                        <td class="date-cell">
                            <div class="date-info">
                            <span class="date-label">Assembly Date:</span>
                            <span class="date-value">{{ item.assembly_date }}</span>
                            </div>
                            <div class="date-info">
                            <span class="date-label">Game Date:</span>
                            <span class="date-value">{{ item.game_date }}</span>
                            </div>
                        </td>
                        <td
                            class="text-center value-cell"
                            v-for="(rowval, i) in item.values"
                            :key="i"
                        >
                            {{ rowval }}
                        </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </div>
          </v-card-text>
          
        </v-card>
      </v-dialog>
    </div>
  </template>
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { GAME_TABLE_API} from "@/constants/APIUrls";
  import Axios from "@/api/BaseAxios";
  export default {
    name: "GameTableModal",
     components: {
      },
    data() {
      return {
        loading: false,
        colorMap: {}, // To store colors for each game date
        graphgameid: "",
        showTable: false,
        tableresponseData: {},
        labels: [],
      };
    },
    computed: {
      ...mapGetters({
        showGetters: "gameManagement/getGameTableShow",
        selectGameData: "gameManagement/getGame",
      }),
      show: {
        get() {
          return this.showGetters;
        },
        set(value) {
          return this.toggleGameTableModal({ show: value });
        },
      },
    },
    watch: {
      show(value) {
        if (value) {
          this.openModal();
        } else {
          this.closeModal();
        }
      },
    },
    methods: {
      ...mapActions({
        toggleGameTableModal: "gameManagement/toggleGameTableModal",
        showToast: "snackBar/showToast",
      }),

       
      openModal() {
        this.getTblGraphData();
      },
      closeModal() {
        this.loading = false;
      },
     
      getTblGraphData(){
        const successHandler= (res) => {
            this.loaded = true;
            this.tableresponseData = res.data.table_data;
            this.labels = this.tableresponseData.columns.map((label) => {
            return label
            .replace("days_45", "Days 45")
            .replace("days_30", "Days 30")
            .replace("days_21", "Days 21")
            .replace("days_14", "Days 14")
            .replace("days_10", "Days 10")
            .replace("days_07", "Days 07")
            .replace("days_03", "Days 03")
            .replace("days_01", "Days 01")
            .replace("game_day", "Game Day")
            .replace("final", "Final")
            .replace("door_sale", "Door sale");
            });
        };


        let tableData = {};
        tableData["game_id"] = this.$route.query.game_id;
        Axios.request_GET(
            GAME_TABLE_API,
            tableData,
            {},
            successHandler,
            failureHandler,
            false,
            true
        );

        const failureHandler = (res) => {
            console.log(res);
        };
      },
    },
    mounted() {
        //this.getTblGraphData();
    },
  };


  </script>
  <style scoped>
  /* Add any custom styles here */
  .ticketTextHeading {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    /* identical to box height, or 45px */
  
    color: #2c1963;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
  } 
  
  .table-header {
    background: #e7e5ed;
    font-weight: bold;
  }
  
  .date-cell {
    padding: 10px;
    background-color: #f9f9f9;
    font-size: 14px;
  }
  
  .date-info {
    margin-bottom: 5px;
  }
  
  .date-label {
    font-weight: bold;
    font-size: 13px;
    color: #6c757d;
  }
  
  .date-value {
    display: inline-block;
    margin-left: 5px;
    color: #333;
  }
  
  .value-cell {
    padding: 10px;
    font-size: 16px;
    background-color: #ffffff;
  }
  
  .elevation-8 {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .textHeader {
    padding: 10px;
    font-size: 16px;
    color: #333;
  }
  
  .v-dialog__content.v-dialog {
    transform-origin: center center;
    max-width: 1000px !important;
   }
</style>
  